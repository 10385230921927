import { AngularFirestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import { DataAccessHelpersService } from "./data-access-helpers.service";
import { ParcAccessService } from "./parc-access.service";
import { UtilsService } from "../../@core/utils/utils.service";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DevisService {
  filteredDevis: any[];
  DevisVente$ = new BehaviorSubject([]);

  /**
   * Returns an Observable of quotes with a given status
   * @param parcId Parc Id
   * @param op Compare operator
   * @param value Quote status
   */
  getAllDevisbyType(
    parcId: string,
    op: string,
    value: string
  ): Observable<IDevis[]> {
    return this.dhs.getCollectionWhere(`yards/${parcId}/devis`, [
      { left: "status", operator: op, right: value },
    ]);
  }

  getBillsRT(parcId: string, type: string, liner?: string) {
    let billFilter = [{ left: "status", operator: "==", right: type }];
    liner !== ""
      ? billFilter.push({ left: "armateur", operator: "==", right: liner })
      : null;
    return this.dhs.getCollectionWhereByOrder(
      `yards/${parcId}/devis`,
      billFilter,
      "createdAt",
      "desc"
    );
  }
  getDevisCtr(parcId): Promise<ICtrDevis[]> {
    return this.dhs.getCollectionOnce(`yards/${parcId}/devis_vente`);
  }

  /**
   * Update quote
   * @param path Firestore document path
   * @param data Quote data
   */
  updateDevis(path: string, data: any) {
    return this.afs.doc(path).update(data);
  }

  deleteDevisByPath(path: string) {
    return this.afs.doc(path).delete();
  }

  /**
   * Set a new
   * @param userId User ID
   * @param data Notification data
   * @param notifId Notification id
   */
  setNotifications(
    userId: string,
    data: Partial<INotification>,
    notifId: string
  ) {
    return this.afs
      .collection(`users/${userId}/notifications`)
      .doc(notifId)
      .set(data);
  }
  /**
   * Update notificiations status
   * @param user User id
   * @param id Notif id
   * @param data Notification data
   */
  updateNotifications(user: string, id: string, data: Partial<INotification>) {
    return this.afs
      .collection(`users/${user}/notifications`)
      .doc(id)
      .update(data);
  }
  /**
   * Returns all notification have been read
   * @param userId User id
   */
  getNotifications(userId: string): Observable<INotification[]> {
    return this.dhs.getCollectionWhere(`users/${userId}/notifications`, [
      { left: "isRead", operator: "==", right: false },
    ]);
  }
  /**
   * Returns all quotes for a given yard
   * @param parcId Parc ID
   */
  getAllDevis(parcId: string): Promise<IDevis[]> {
    return this.dhs.getCollectionOnce(`yards/${parcId}/devis`);
  }
  deleteDevis(parcId: string, id: string) {
    return this.afs.doc(`yards/${parcId}/devis/${id}`).delete();
  }
  getDevisByNumserie(parcID, num) {
    return this.dhs.getDocumentOnce(`yards/${parcID}/devis/${num}`);
  }
  moveToTrash(devis, parcId) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("trash")
      .doc(devis.id)
      .set(devis);
  }
  getDevis(parcId: string, id: string) {
    return this.dhs.getDocumentOnce(`yards/${parcId}/devis/${id}`);
  }

  setDevis(parcId, id, data) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("devis")
      .doc(id)
      .set(data);
  }

  getDevisInter(parcId: string, id: string) {
    return this.dhs.getDocumentOnce(`yards/${parcId}/devisInter/${id}`);
  }

  deleteNotification(user: string, id: string) {
    return this.afs.collection(`users/${user}/notifications`).doc(id).delete();
  }

  async borrowPieceFromDevis(parcId, devis, allDevis) {
    const { missingPieces, id } = devis;
    if (missingPieces.length) {
      //************ check stock quantity (check if stock should be a donor)**********
      const stock = await this.pas.getStockInventroyOnce(parcId);
      const allRepairs = await this.afs.firestore
        .collection("yards")
        .doc(parcId)
        .collection("tasks")
        .where("type", "==", "repair")
        .get();

      const groupedStockByPiece = this.us.singleGroupBy(stock, "refPiece");

      const availableQuantityPerPiece = {};

      Object.keys(groupedStockByPiece).forEach((key) => {
        const totalAvailableQuantity = groupedStockByPiece[key].reduce(
          (acc, curr) => acc + curr.availableQuantity,
          0
        );
        const totalStockQuantity = groupedStockByPiece[key].reduce(
          (acc, curr) => acc + curr.stockQuantity,
          0
        );

        availableQuantityPerPiece[key] = {
          availableQuantity: totalAvailableQuantity,
          stockQuantity: totalStockQuantity,
        };
      });

      //  console.log("stockGrouped", availableQuantityPerPiece);

      const calculateNeededPieceQty = (stock, ref_part, neededQty) => {
        const availableQuantity = stock[ref_part].availableQuantity;

        const sum = availableQuantity + neededQty;

        if (sum >= 0) {
          return neededQty - sum;
        }
        if (sum < 0) {
          return neededQty;
        }
      };

      //*********** filtering and formating devis (>p,pas d'acccord ligne,accord depot) ***********
      const formatAllDevis = (
        allDevis,
        allRepairs,
        availableQuantityPerPiece
      ) => {
        const formattedDevis = allDevis
          .filter(
            (devis) =>
              devis.limitExceededReefer &&
              !("ref" in devis) &&
              devis.validate &&
              devis.id !== id
          )
          // the oldest first
          .sort((a, b) => a.createdAt - b.createdAt);

        // const devisWithborrowedPieces = allDevis.filter(
        //   (devis) => devis.id !== id && "ref" in devis && "donorsList" in devis
        // );
        // console.log("devisWithborrowedPieces", devisWithborrowedPieces);

        //    console.log("sorted", formattedDevis);

        const potentialDonors = [];

        //    console.log({ formattedDevis });

        for (let i in formattedDevis) {
          const codeEntries = formattedDevis[i].codeEntries;
          let stockQuantityByPiece = availableQuantityPerPiece;
          let consumedPieces = [];

          for (let j in codeEntries) {
            if (
              codeEntries[j].hasOwnProperty("spare_parts") &&
              codeEntries[j].spare_parts.length
            ) {
              const piece_ref = codeEntries[j].piece_ref;
              const nbr_pieces = codeEntries[j].nbr_pieces;
              const pieceStock = stockQuantityByPiece[piece_ref];

              let stockToSustractForRepairs = 0;
              for (const repairTask of allRepairs) {
                if (repairTask.startedAt) continue;

                for (const repairOp of repairTask.operations) {
                  if (repairOp.piece_ref && repairOp.piece_ref === piece_ref) {
                    // console.log("[debug] found repair task: ", repairTask);
                    stockToSustractForRepairs += repairOp.nbr_pieces;
                  }
                }
              }

              /*    console.log("[debug] stock ops: ", {
                stockToSustractForRepairs,
                stockQuantityByPiece,
                piece_ref,
                pieceStock,
              }); */

              let stockQuantity =
                (pieceStock ? pieceStock.stockQuantity : 0) -
                stockToSustractForRepairs;
              //   console.log("[debug] final stock quantity");

              // check for donated pieces
              // if (
              //   devisWithborrowedPieces.length &&
              //   devisWithborrowedPieces.some((devis) =>
              //     devis.donorsList.some((item) => item.ref_part === piece_ref)
              //   )
              // ) {
              //   let pieceReceivedByDonation = { nbr_pieces: 0 };

              //   for (let i in devisWithborrowedPieces) {
              //     const donorsList = devisWithborrowedPieces[i].donorsList;
              //     for (let j in donorsList) {
              //       if (donorsList[j].ref_part === piece_ref) {
              //         pieceReceivedByDonation = donorsList[j];
              //       }
              //     }
              //   }

              //   console.log("pieceReceivedByDonation", pieceReceivedByDonation);
              //   const nbr_pieces_received = pieceReceivedByDonation.nbr_pieces;

              //   stockQuantity = stockQuantity - nbr_pieces_received;
              // }

              if (stockQuantity > 0) {
                const afterSubstraction = stockQuantity - nbr_pieces;

                // take all the pieces
                if (afterSubstraction >= 0) {
                  // stockQuantity --
                  stockQuantityByPiece[piece_ref].stockQuantity =
                    stockQuantity - nbr_pieces;

                  potentialDonors.push({
                    id: formattedDevis[i].id,
                    createdAt: formattedDevis[i].createdAt,
                    consumedPieces: [
                      ...consumedPieces,
                      { ref_part: piece_ref, nbr_pieces: nbr_pieces },
                    ],
                  });
                }

                // take available pieces
                if (afterSubstraction < 0) {
                  // stockQuantity --
                  stockQuantityByPiece[piece_ref].stockQuantity = stockQuantity;

                  potentialDonors.push({
                    id: formattedDevis[i].id,
                    createdAt: formattedDevis[i].createdAt,
                    consumedPieces: [
                      ...consumedPieces,
                      {
                        ref_part: piece_ref,
                        nbr_pieces: stockQuantity,
                      },
                    ],
                  });
                }
              }
            }
          }
        }

        // the newest first
        return potentialDonors.sort((a, b) => b.createdAt - a.createdAt);
      };
      const formattedDevis = formatAllDevis(
        allDevis,
        allRepairs,
        availableQuantityPerPiece
      );

      //***********formating missingPieces ***********
      const formatMissingPieces = (devis) => {
        const formattedMissingPieces = devis.codeEntries
          .filter(
            (ce) => ce.hasOwnProperty("spare_parts") && ce.spare_parts.length
          )
          .filter((ce) => missingPieces.includes(ce.spare_parts[0].ref_part))
          .map((ce) => {
            return {
              ref_part: ce.spare_parts[0].ref_part,
              nbr_pieces: calculateNeededPieceQty(
                availableQuantityPerPiece,
                ce.spare_parts[0].ref_part,
                ce.nbr_pieces
              ),
              donors: [],
            };
          });
        return formattedMissingPieces;
      };

      const formattedMissingPieces = formatMissingPieces(devis);

      //*********** find donors ***********
      const findDonors = (formattedDevis, formattedMissingPieces) => {
        let donorsList = formattedMissingPieces;
        for (let i in formattedDevis) {
          for (let j in donorsList) {
            let samePiece = formattedDevis[i].consumedPieces.find(
              (cp) => cp.ref_part === donorsList[j].ref_part
            );
            let currentPiece = donorsList[j];
            if (samePiece !== undefined) {
              if (
                currentPiece.donors.reduce(
                  (acc, curr) => acc + curr.nbr_pieces_donated,
                  0
                ) < currentPiece.nbr_pieces
              ) {
                currentPiece.donors.push({
                  id: formattedDevis[i].id,
                  nbr_pieces_donated:
                    samePiece.nbr_pieces <= currentPiece.nbr_pieces
                      ? samePiece.nbr_pieces
                      : currentPiece.nbr_pieces -
                        currentPiece.donors.reduce(
                          (acc, curr) => acc + curr.nbr_pieces_donated,
                          0
                        ),
                });
              }
            }
          }
        }
        return donorsList;
      };

      /*       console.log("[debug] about to exec findDonors with data: ", {
        formattedDevis,
        formattedMissingPieces,
      }); */
      const donorsList = findDonors(formattedDevis, formattedMissingPieces);
      // console.log("[debug] donorsList", donorsList);

      //*********** check if exchange is possible ***********
      const checkIfExchangePossible = (donorsList) => {
        if (!donorsList.length) {
          return false;
        }
        for (let i in donorsList) {
          if (
            donorsList[i].donors.reduce(
              (acc, curr) => acc + curr.nbr_pieces_donated,
              0
            ) < donorsList[i].nbr_pieces
          ) {
            return false;
          }
        }

        return true;
      };
      /*   console.log(
        "[debug] checkIfExchangePossible",
        checkIfExchangePossible(donorsList)
      ); */

      //*********** exchange ***********
      if (checkIfExchangePossible(donorsList)) {
        const batch = this.afs.firestore.batch();
        // update reciever devis
        const recieverRef = this.afs.firestore
          .collection("yards")
          .doc(parcId)
          .collection("devis")
          .doc(devis.id);

        batch.update(recieverRef, {
          missingPieces: [],
          donorsList,
          reparationReef: Date.now(),
        });

        // deleting pending task
        const relatedPendingTaskRef = this.afs.firestore
          .collection(`yards/${parcId}/pendingTasks`)
          .where("estimateId", "==", devis.id);
        relatedPendingTaskRef.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.delete();
          });
        });

        // add repair task
        const tasksRef = this.afs.firestore
          .collection(`yards/${parcId}/tasks`)
          .doc();
        const repairTask = {
          operations: devis.codeEntries.filter((entry) => !entry.box),
          armateur: devis.armateur,
          client: devis.client,
          codeIso: devis.codeIso,
          conType: devis.conType,
          numSerie: devis.numSerie,
          conSize: devis.conSize,
          estimateId: devis.estimateId,
          inProgress: false,
          type: "repair",
          userRole: "reefTech",
          createdAt: Date.now(),
          sizeOfPhotos:
            devis.sizeOfPictures.length > 0 ? devis.sizeOfPictures : [],
        };
        // create task repair
        batch.set(tasksRef, repairTask);

        let piecesGroupedByDonor = [];
        donorsList.map((mp) => {
          mp.donors.map((donor) => {
            if (
              piecesGroupedByDonor.length &&
              piecesGroupedByDonor.some((dp) => dp.id === donor.id)
            ) {
              piecesGroupedByDonor = piecesGroupedByDonor.map((dp) => {
                if (dp.id === donor.id) {
                  dp.parts.push(mp.ref_part);
                }
                return dp;
              });
            } else {
              piecesGroupedByDonor.push({
                id: donor.id,
                parts: [mp.ref_part],
              });
            }
          });
        });
        // update donors devis
        piecesGroupedByDonor.forEach((donor) => {
          const donorRef = this.afs.firestore
            .collection("yards")
            .doc(parcId)
            .collection("devis")
            .doc(donor.id);
          batch.update(donorRef, {
            missingPieces: [
              ...allDevis
                .find((d) => d.id === donor.id)
                .missingPieces.filter(
                  (mp) => !donor.parts.some((p) => p === mp)
                ),
              ...donor.parts,
            ],
          });
        });
        await batch.commit();
      }
    }
  }

  async displayDevisCtr(devisType) {
    try {
      const parcId = localStorage.getItem("parcId");
      const devisList = await this.getDevisCtr(parcId);
      // const parcData = await this.pas.getYardOnceById(parcId);
      // this.depotDateFormat =
      //   parcData.dateFormat === "US" ? "MM/dd/yyyy hh:mm a" : "dd/MM/yyyy HH:mm";
      // this.isUSDepot = parcData.currency === "Dollar" ? true : false;
      const data = devisList.filter((el) => el.type === devisType);
      const filteredDevis = [];
      data.map((el) => filteredDevis.push(el));
      this.filteredDevis = filteredDevis;
      return this.DevisVente$.next(this.filteredDevis);
    } catch (error) {
      console.log("error here", error);
    }
  }
  async getPieceStockQuantityByRef(parcId, refPiece) {
    let stockQuantity = 0;
    try {
      await this.afs.firestore
        .collection(`yards/${parcId}/spare-parts-inventory`)
        .where("refPiece", "==", refPiece)
        .get()
        .then((res) => {
          res.docs.forEach((doc) => {
            stockQuantity += doc.data().stockQuantity;
          });
        });

      return stockQuantity;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  constructor(
    private dhs: DataAccessHelpersService,
    private afs: AngularFirestore,
    private pas: ParcAccessService,
    private us: UtilsService
  ) {}
}
