import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { environment } from "../../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class ContractService {
  constructor(private afAuth: AngularFireAuth) {}

  API_ENDPOINT = environment.API_ENDPOINT;

  headers = { "Content-Type": "application/json" };

  getIdToken() {
    return this.afAuth.auth.currentUser.getIdToken(true);
  }
  /**
   * Return EMR Contract for a given client id
   * @param parc Parc ID
   * @param client Client ID
   */
  getClientContract(parc: string, client: string): Promise<Response> {
    const requestBody = {
      parc: parc,
      client: client,
    };
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/emr_operation`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(requestBody),
        });
      })
      .catch((err) => Promise.reject(err));
  }
  /**
   * Return EMR Contract for a given catalog
   * @param catalog Catalog
   */
  getConsumable(parcId: string): Promise<Response> {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/getConsumable?parcId=${parcId}`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "GET",
        });
      })
      .catch((err) => Promise.reject(err));
  }

  getBoxCatalog(): Promise<Response> {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/getBoxCatalog`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "GET",
        });
      })
      .catch((err) => Promise.reject(err));
  }

  getChassisCatalog(): Promise<Response> {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/getChassisCatalog`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "GET",
        });
      })
      .catch((err) => Promise.reject(err));
  }

  /**
   * Return EMR Contract for a given catalog
   * @param catalog Catalog
   */
  getCatalog(catalog: string): Promise<Response> {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/getSpareParts`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify({ construct: catalog, distinct: true }),
        });
      })
      .catch((err) => Promise.reject(err));
  }

  /**
   * Return specific prices
   */
  getSpecificWs(
    parc,
    client,
    armateur,
    code,
    description,
    shortcut
  ): Promise<Response> {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/getSpecificWs`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify({
            parc,
            client,
            armateur,
            code,
            description,
            shortcut,
          }),
        });
      })
      .catch((err) => Promise.reject(err));
  }
}
