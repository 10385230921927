import { Component } from "@angular/core";

@Component({
  selector: "ngx-footer",
  styleUrls: ["./footer.component.scss"],
  template: `
    <div class="centery">
      <span i18n style="text-align: center">
        <b style="text-align: center"> 2.1.5.v100 </b>
      </span>
    </div>
  `,
})
export class FooterComponent {}
